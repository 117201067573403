import {
	For,
	Match,
	Suspense,
	createSignal,
	useContext,
	Switch,
	Show,
	createEffect,
	createMemo,
	ErrorBoundary as SolidErrorBoundary,
} from 'solid-js';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import tz from 'dayjs/plugin/timezone';
import {
	ActivityIndicator,
	Button,
	Calendar,
	DialogContent,
	DialogAction,
	DialogActions,
	Dialog,
	Link,
	TextLink,
	DialogTrigger,
	Container,
	Page,
} from '@troon/ui';
import { Meta, Title } from '@solidjs/meta';
import { useTrackEvent } from '@troon/analytics';
import { createAsync, Navigate, useSearchParams } from '@solidjs/router';
import { createStore } from 'solid-js/store';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import { Icon } from '@troon/icons';
import { gql } from '../../../../../graphql';
import { FacilityCtx } from '../../../../../providers/facility';
import { FacilityHeader } from '../../../../../components/facility/header';
import { Grid, GridMain, GridSidebar } from '../../../../../components/layouts/grid';
import { getSchemaString } from '../../../../../modules/schema/schema';
import { breadcrumbs } from '../../../../../modules/schema/breadcrumb-list';
import { image } from '../../../../../modules/schema/image';
import { webpage } from '../../../../../modules/schema/webpage';
import { TeeTime } from '../../../../../components/tee-time';
import { cachedQuery } from '../../../../../graphql/cached-get';
import { dayTimeToDate } from '../../../../../modules/date-formatting';
import { useUtmParams } from '../../../../../providers/utm';
import { ErrorBoundary } from '../../../../../components/error-boundary';
import { TeeTimeAlertDialog, TeeTimeAlertPrompt } from './components/tee-time-alert';
import { TeeTimeFilters } from './components/tee-time-filters';
import { PageWelcomeBanner } from './components/welcome-banner';
import type { Params } from '@solidjs/router';
import type { Store } from './components/tee-time-filters';
import type { DayFragment, Facility, CourseTeeTime, TeeTimeFragment } from '../../../../../graphql';

dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);
dayjs.extend(utc);
dayjs.extend(tz);

function queryParamStore<T extends Record<string, unknown>>(params: Partial<Params>, store: T): T {
	const out = { ...store };
	for (const [key, val] of Object.entries(out)) {
		if (!(key === 'players' || key === 'lat' || key === 'lon') && typeof params[key] === 'undefined') {
			continue;
		}
		const pVal = params[key] as string;
		if (typeof pVal === 'undefined') {
			continue;
		}
		if (Array.isArray(val)) {
			// @ts-expect-error TS is weird about the generic here
			out[key] = pVal.split(',');
		} else if (typeof val === 'boolean') {
			// @ts-expect-error TS is weird about the generic here
			out[key] = !!JSON.parse(pVal);
		} else if (key === 'players' || typeof val === 'number') {
			// @ts-expect-error TS is weird about the generic here
			out[key] = parseInt(pVal, 10);
		} else if (key === 'lat' || key === 'lon' || typeof val === 'number') {
			// @ts-expect-error TS is weird about the generic here
			out[key] = parseFloat(pVal);
		} else {
			// @ts-expect-error TS is weird about the generic here
			out[key] = pVal;
		}
	}
	return out;
}

export default function ReserveTeeTime() {
	const trackEvent = useTrackEvent();
	const facility = useContext(FacilityCtx);
	const utm = useUtmParams();

	const now = dayjs(Date.now()).tz(facility()?.facility.timezone);
	const today = now.startOf('day');
	const initialDay = now.hour() < 16 ? now : now.add(1, 'day');
	const [showMobileDatePicker, setShowMobileDatePicker] = createSignal(false);
	const [showDateWarning, setShowDateWarning] = createSignal(false);
	const [showFilters, setShowFilters] = createSignal(false);

	const [params, setParams] = useSearchParams();

	const [filters, setFilters] = createStore<Store>(
		queryParamStore<Store>(params, {
			includeCart: false,
			players: undefined,
			courseIds: facility()?.facility.courses.map((c) => c.id) ?? [],
			startAt: 0,
			endAt: 24,
			date: initialDay.format('YYYY-MM-DD'),
		}),
	);
	const [showBanner, setShowBanner] = createSignal(false);

	createEffect(() => {
		setParams(
			{
				...filters,
				courseIds: filters.courseIds.join(','),
			},
			{ replace: true },
		);
	});

	createEffect(() => {
		setShowBanner(!!utm().source);
	});

	createEffect(() => {
		if (!facility()?.facility.id) {
			return;
		}
		const date = dayjs(filters.date);

		trackEvent('changeTeeTimeDay', {
			courseId: facility()?.facility.id,
			year: date.year(),
			month: date.month() + 1,
			day: date.date(),
			hour: 0,
			minute: 0,
		});
	});

	const maxDate = createMemo(() => {
		return facility()?.facility.courses.reduce((max, course) => {
			const day = course.bookingWindowDay as DayFragment;
			const date = dayjs.tz(`${day.year}-${day.month}-${day.day}`, facility()?.facility.timezone).endOf('day');
			return max.isBefore(date) ? date : max;
		}, today);
	});

	const selectedDay = createMemo(() => dayjs.tz(filters.date, facility()?.facility.timezone).startOf('day'));

	const filterCount = createMemo(() => {
		return (
			(filters.players ? 1 : 0) +
			(filters.includeCart ? 1 : 0) +
			(filters.startAt > 0 || filters.endAt < 24 ? 1 : 0) +
			(filters.courseIds.length !== (facility()?.facility.courses.length ?? 0) ? 1 : 0)
		);
	});

	const teeTimes = createAsync(
		async () => {
			const { date, ...data } = filters;
			const day = dayjs(date);
			const params = {
				...data,
				year: day.year(),
				month: day.month() + 1,
				day: day.date(),
			};
			trackEvent('getTeeTimesRequest', params);
			const res = await getTeeTimes(params);
			if (res) {
				trackEvent('getTeeTimesSuccess', { ...params, resultsCount: res?.teeTimes.length });
			} else {
				trackEvent('getTeeTimesFailure', params);
			}
			return res;
		},
		{ deferStream: true },
	);

	return (
		<Suspense>
			<Show when={facility()?.facility}>
				{(facility) => (
					<Show when={facility().isBookable} fallback={<Navigate href={`/course/${facility().slug}`} />}>
						<>
							<Title>{`${facility()?.name} | Book tee times | Troon`}</Title>
							<Meta
								name="description"
								content={`Reserve golf tee times at ${facility()?.name} and earn Troon Rewards points.`}
							/>
							<script
								type="application/ld+json"
								innerText={getSchemaString([
									breadcrumbs(`/course/${facility().slug}/reserve-tee-time`, [
										{ name: 'Home', pathname: '/' },
										{ name: 'Courses', pathname: '/courses' },
										{ name: facility().name ?? '', pathname: `/course/${facility().slug}` },
										{ name: 'Reserve tee times', pathname: `/course/${facility().slug}/reserve-tee-time` },
									]),
									image(facility().metadata!.hero!.url ?? ''),
									webpage(`/course/${facility().slug}`, {
										title: `${facility.name} | Reserve tee times | Troon Rewards | Book tee times`,
									}),
								])}
							/>
							<Show when={showBanner()}>
								<div class="z-10 -mb-8">
									<PageWelcomeBanner facility={facility()} handleDismiss={() => setShowBanner(false)} />
								</div>
							</Show>
							<Page>
								<Container>
									<FacilityHeader facility={facility() ?? {}} showHero linked={false}>
										<div class="flex flex-row flex-wrap justify-end gap-4">
											<Button
												as={Link}
												href={`/course/${facility().slug}`}
												appearance="transparent-current"
												class="px-2 py-1 text-xl lg:text-3xl"
											>
												<Icon name="info" title={`More information about ${facility().name}`} />
											</Button>
											<TeeTimeAlertDialog>
												<DialogTrigger appearance="transparent-current" class="px-2 py-1 text-xl lg:text-3xl">
													<Icon name="bell-ring" title="Set a tee time alert" />
												</DialogTrigger>
											</TeeTimeAlertDialog>
										</div>
									</FacilityHeader>

									<Grid>
										<GridMain class="order-2">
											<div
												// eslint-disable-next-line tailwindcss/no-arbitrary-value
												class="sticky inset-x-0 top-[calc(4rem-1px)] z-20 -mx-4 flex grow flex-row flex-nowrap items-center justify-between gap-2 border-y border-y-neutral bg-white p-2 px-4 md:static md:mx-0 md:w-full md:border-none md:px-0 md:pb-4"
											>
												<h2 class="order-2 hidden text-nowrap font-semibold normal-case text-black md:block md:text-2xl">
													{selectedDay().format('dddd, MMMM D')}
												</h2>
												<div class="order-2 md:hidden">
													<Dialog
														key="tee-time-calendar"
														open={showMobileDatePicker()}
														onOpenChange={setShowMobileDatePicker}
													>
														<DialogTrigger appearance="transparent" class="flex-nowrap px-2 py-1">
															<h2 class="text-nowrap font-semibold normal-case text-black">
																{selectedDay().format('dddd, MMMM D')}
															</h2>
															<Icon name="unfold-more" />
															<span class="sr-only">Select date</span>
														</DialogTrigger>
														<DialogContent header="Select a date" headerLevel="h3">
															<div class="flex flex-row justify-center">
																<Calendar
																	label="Tee times {date}"
																	dayLabel="Show tee times for {date}"
																	minDate={today}
																	maxDate={maxDate()}
																	selectedDate={selectedDay()}
																	onSelect={(date) => {
																		const day = dayjs(date);
																		setFilters({
																			date: day.format('YYYY-MM-DD'),
																		});
																		setShowMobileDatePicker(false);
																	}}
																	onSelectInvalid={() => {
																		setShowDateWarning(true);
																	}}
																	timezone={facility().timezone ?? 'UTC'}
																/>
															</div>
														</DialogContent>
													</Dialog>
													<Dialog
														key="tee-time-date-out-of-range"
														open={showDateWarning()}
														onOpenChange={setShowDateWarning}
													>
														<DialogContent>
															<div class="flex flex-col gap-8">
																<p>
																	This day is outside of the booking window for {facility().name}. For more information,
																	contact the course at{' '}
																	<TextLink href={`tel:${facility().metadata?.phone}`}>
																		{facility().metadata?.phone}
																	</TextLink>
																	.
																</p>
																<DialogActions>
																	<DialogAction onClick={() => setShowDateWarning(false)}>Okay</DialogAction>
																</DialogActions>
															</div>
														</DialogContent>
													</Dialog>
												</div>
												<Button
													appearance="transparent"
													class="order-1 shrink grow-0 px-2 py-1 md:hidden"
													disabled={selectedDay()?.isSameOrBefore(today, 'day') ?? true}
													onClick={() => {
														const prev = selectedDay().subtract(1, 'day');
														setFilters({
															date: prev.format('YYYY-MM-DD'),
														});
													}}
												>
													<Icon name="chevron-left" />
													<span class="sr-only">Previous day</span>
												</Button>
												<Button
													appearance="transparent"
													class="order-3 shrink grow-0 px-2 py-1 md:hidden"
													disabled={selectedDay()?.isSameOrAfter(maxDate(), 'day') ?? true}
													onClick={() => {
														const next = selectedDay().add(1, 'day');
														setFilters({
															date: next.format('YYYY-MM-DD'),
														});
													}}
												>
													<Icon name="chevron-right" />
													<span class="sr-only">Next day</span>
												</Button>
												<SolidErrorBoundary fallback={null}>
													<Suspense fallback={null}>
														<p class="order-4 hidden lg:block">{teeTimes()?.teeTimes.length ?? 0} tee times</p>
													</Suspense>
												</SolidErrorBoundary>
											</div>

											<div class="container mx-auto flex flex-row items-center justify-between py-4 ps-2 sm:max-w-none sm:shrink lg:hidden">
												<SolidErrorBoundary fallback={null}>
													<Suspense fallback={null}>
														<p>{teeTimes()?.teeTimes.length ?? 0} tee times</p>
													</Suspense>
												</SolidErrorBoundary>
												<Dialog key="tee-time-filter" open={showFilters()} onOpenChange={setShowFilters}>
													<DialogTrigger appearance="transparent" class="ms-auto flex shrink grow-0 gap-2">
														Filter
														<Switch>
															<Match when={filterCount()}>
																<span class="inline-flex size-6 items-center justify-center rounded-full bg-brand text-white">
																	{filterCount()}
																</span>
															</Match>
															<Match when>
																<Icon name="filter" />
															</Match>
														</Switch>
													</DialogTrigger>
													<DialogContent header="Filter tee times" headerLevel="h2">
														<div class="flex flex-col gap-6">
															<TeeTimeFilters
																facility={facility() as Facility}
																onSelectPlayers={(count) => setFilters('players', count)}
																onSelectCourse={(courseId) => {
																	if (courseId === '__all__') {
																		setFilters('courseIds', facility().courses.map((c) => c.id) ?? []);
																		return;
																	}
																	setFilters('courseIds', [courseId]);
																}}
																onSelectTime={(timeframe) => setFilters({ startAt: timeframe[0], endAt: timeframe[1] })}
																onSwitchIncludesCart={(include) => setFilters('includeCart', include)}
																minDate={today}
																maxDate={maxDate()}
																onSelectDate={(dateString) => {
																	setFilters({
																		date: dateString,
																	});
																}}
																{...filters}
															/>
															<Button type="button" onClick={() => setShowFilters(false)}>
																View tee times
															</Button>
														</div>
													</DialogContent>
												</Dialog>
											</div>
											<div class="rounded border border-neutral-500">
												<ErrorBoundary>
													<Suspense
														fallback={
															<div class="p-8 text-neutral-700">
																<ActivityIndicator>Loading tee times…</ActivityIndicator>
															</div>
														}
													>
														<ul class="flex h-full flex-col">
															<For
																each={teeTimes()?.teeTimes}
																fallback={
																	<>
																		<li class="flex basis-32 items-center justify-start border-b border-neutral-500 px-8">
																			<p class="text-start text-lg">
																				No available tee times for {selectedDay().format('dddd, MMMM D')}.<br />
																				Please select another day{' '}
																				<Show fallback={'.'} when={facility().metadata?.phone}>
																					{(phone) => (
																						<>
																							or call the course at{' '}
																							<TextLink href={`tel:${phone()}`}>{phone()}</TextLink>.
																						</>
																					)}
																				</Show>
																			</p>
																		</li>
																		<li class="p-6">
																			<TeeTimeAlertPrompt />
																		</li>
																	</>
																}
															>
																{(teeTime, index) => {
																	return (
																		<>
																			<li class="border-b border-neutral-500">
																				<TeeTime
																					{...(teeTime as TeeTimeFragment)}
																					showCourse={(facility().courses.length ?? 0) > 1}
																				/>
																			</li>
																			<Show
																				when={
																					index() ===
																					largestGapIndex((teeTimes()?.teeTimes as Array<CourseTeeTime>) ?? [])
																				}
																			>
																				<li class="border-b border-neutral-500 p-6">
																					<TeeTimeAlertPrompt
																						selectedDay={dayjs(filters.date).toDate()}
																						selectedPlayers={filters.players}
																						selectedTime={[filters.startAt, filters.endAt]}
																					/>
																				</li>
																			</Show>
																		</>
																	);
																}}
															</For>
														</ul>
													</Suspense>
												</ErrorBoundary>
											</div>
										</GridMain>

										<GridSidebar class="order-1 row-start-2 hidden lg:block">
											<div
												// eslint-disable-next-line tailwindcss/no-arbitrary-value
												class="sticky top-24 flex max-h-[calc(100dvh-8rem)] flex-col gap-6 overflow-y-scroll rounded border border-neutral-500 p-6"
											>
												<h3 class="text-lg font-semibold">Filter tee times</h3>
												<TeeTimeFilters
													facility={facility() as Facility}
													onSelectPlayers={(count) => setFilters('players', count)}
													onSelectCourse={(courseId) => {
														if (courseId === '__all__') {
															setFilters('courseIds', facility().courses.map((c) => c.id) ?? []);
															return;
														}
														setFilters('courseIds', [courseId]);
													}}
													onSelectTime={(timeframe) => setFilters({ startAt: timeframe[0], endAt: timeframe[1] })}
													onSwitchIncludesCart={(include) => setFilters('includeCart', include)}
													minDate={today}
													maxDate={maxDate()}
													onSelectDate={(dateString) => {
														setFilters({
															date: dateString,
														});
													}}
													{...filters}
												/>
											</div>
										</GridSidebar>
									</Grid>
								</Container>
							</Page>
						</>
					</Show>
				)}
			</Show>
		</Suspense>
	);
}

function largestGapIndex(teeTimes: Array<CourseTeeTime>) {
	let largestMs = 1000 * 60 * 45; // 45 minutes in ms
	let i = 0;
	for (; i < teeTimes.length; i++) {
		const teeTime = teeTimes[i]!;
		const nextTeeTime = teeTimes[i + 1];
		if (!nextTeeTime) {
			return i;
		}

		const diffMs = dayTimeToDate(nextTeeTime.dayTime).valueOf() - dayTimeToDate(teeTime.dayTime).valueOf();
		if (diffMs >= largestMs) {
			return i;
		}
		largestMs = Math.max(largestMs, diffMs);
	}

	return i;
}

const teeTimesQuery = gql(`
query teeTimes(
	$courseIds: [String!]!,
	$year: Int!,
	$month: Int!,
	$day: Int!,
	$startAt: Int!,
	$endAt: Int!,
	$includeCart: Boolean,
	$players: Int
) {
	teeTimes: courseTeeTimes(
		courseIds: $courseIds,
		day: { year: $year, month: $month, day: $day },
		filters: {
			startAt: { hour: $startAt, minute: 0 },
			endAt: { hour: $endAt, minute: 0 },
			players: $players,
			includeCart: $includeCart,
		}
	) {
		...TeeTime
	}
}`);

const getTeeTimes = cachedQuery(teeTimesQuery);
